export const environment = {
    version: '3.33.6.4-nyq',
    production: false,
    apiUrl: 'https://pre-api.cliniweb.com/',
    cloudHubUrl: "https://preproduccion-apps.aspsoluciones.com/CloudHubWebApi/cloudhub",
    cliniwebUrl: "https://preproduccion.cliniweb.com/",
    logLevel: 1,
    searchProfilesPageSize: 8,
    googleApiKey: 'AIzaSyAhEjpF14LorjB9dHPlSMDr0GP81hy2IAU',
    cKey: '2e35f242a46d67eeb74aabc37d5e5d05',
    googleFileViewerUrl: 'https://drive.google.com/viewerng/viewer?embedded=true&url=',
    useElasticsearchForProfiles: true,
    googleCaptchaSiteKey: '6Lf6gBUUAAAAAHb2HNwaQx5TpP6FJcTHjF-yPAjO',
    encryptLocalStorage: true,
    portalUrl: 'http://preproduccion-professional.cliniweb.com/',
    idEmpresaCliniwebPHR: 4243012,
    nombreEmpresaCliniwebPHR: 'cliniweb-phr',
    cliniwebPhrUrl: 'https://preproduccion-app.cliniweb.com/'
};